export const MIXED_VALUE = 'MIXED_VALUE';
export const COMMON_VALUE = 'COMMON_VALUE';
export const MULTIPLE_VALUES_SPLITTER = /[\s,\n]+/;

export const MODAL_STATES = {
  NONE: 'none',
  IDLE: 'idle',
  LOADING: 'loading',
  SAVING: 'saving',
};

export const DOMAIN_TRAFFIC_ACTIONS = {
  UPSTREAM: 'upstream',
  REDIRECT: 'redirect',
};

export const PLUGIN_NAMES = {
  IP_RESTRICTION: 'ip-restriction',
  RESPONSE_TRANSFORMER: 'response-transformer',
  GAIUS_IP_RESTRICTION: 'gaius-ip-restriction',
  GAIUS_REQUEST_LIMIT: 'gaius-request-limit',
  GAIUS_CHALLENGE: 'gaius-challenge',
  GAIUS_REDIRECT: 'gaius-redirect',
  GAIUS_PROXY_CACHE: 'gaius-proxy-cache',
  GAIUS_ADVANCE_PROXY_CACHE: 'gaius-advance-proxy-cache',
  GAIUS_GEOIP: 'gaius-geoip',
  GAIUS_CUSTOM_ERROR_PAGE: 'gaius-custom-error-page',
  GAIUS_UPSTREAM_HOST: 'gaius-upstream-host',
  GAIUS_CROWDSEC_CAPTCHA: 'gaius-crowdsec-captcha',
  GAIUS_BLACKRULES: 'gaius-blackrules',
  GAIUS_SECLINK: 'gaius-seclink',
  GAIUS_BAN: 'gaius-ban',
  GAIUS_CUSTOM_HEADER: 'gaius-custom-header',
  GAIUS_CONFIG: 'gaius-domain-config'
};

export const SCREEN_SIZES = {
  XS: 760,
  SM: 992,
  MD: 1200
}

export const SCREEN_TYPES = {
  XS : 'extra-small',
  SM : 'small',
  MD : 'medium',
  LG : 'large'
}


// xs (for phones - screens less than 768px wide)
// sm (for tablets - screens equal to or greater than 768px wide)
// md (for small laptops - screens equal to or greater than 992px wide)
// lg (for laptops and desktops - screens equal to or greater than 1200px wide)
// // Small devices (landscape phones, 576px and up)
// @media (min-width: 576px) { ... }

// // Medium devices (tablets, 768px and up)
// @media (min-width: 768px) { ... }

// // Large devices (desktops, 992px and up)
// @media (min-width: 992px) { ... }

// // Extra large devices (large desktops, 1200px and up)
// @media (min-width: 1200px) { ... }

export const GAIUS_GEOIP_MODES = {
  BLACKLIST: 'Blacklist',
  WHITELIST: 'Whitelist',
};

export const PLUGIN_DEFAULTS = {
  [PLUGIN_NAMES.GAIUS_REDIRECT]: {
    name: PLUGIN_NAMES.GAIUS_REDIRECT,
    config: {
      redirect_url: '',
      preserve_path: false
    },
    enabled: true,
  },
  [PLUGIN_NAMES.GAIUS_IP_RESTRICTION]: {
    name: PLUGIN_NAMES.GAIUS_IP_RESTRICTION,
    config: {
      allow: [],
      deny: [],
      whitelistMode: 'whitelist',
      allow_spiders: true,
      blacklistMode: 'blacklist',
    },
    enabled: true,
  },
  [PLUGIN_NAMES.RESPONSE_TRANSFORMER]: {
    name: PLUGIN_NAMES.RESPONSE_TRANSFORMER,
    config: {
      add: {
        headers: []
      }
    },
    enabled: true,
  },
  [PLUGIN_NAMES.GAIUS_REQUEST_LIMIT]: {
    name: PLUGIN_NAMES.GAIUS_REQUEST_LIMIT,
    config: {
      banpath: '.*',
      findtime: 240,
      maxretry: 310,
      bantime: '3h',
      only_dynamic: false
    },
    enabled: true,
  },
  [PLUGIN_NAMES.GAIUS_CHALLENGE]: {
    name: PLUGIN_NAMES.GAIUS_CHALLENGE,
    config: {
      challenge: 'js',
    },
    enabled: true,
  },
  [PLUGIN_NAMES.GAIUS_GEOIP]: {
    name: PLUGIN_NAMES.GAIUS_GEOIP,
    config: {
      blacklist_countries: [],
      whitelist_countries: [],
      whitelist_ips: [],
      combine_geoip:false,
      mode: GAIUS_GEOIP_MODES.BLACKLIST,
    },
    enabled: true,
  },
  [PLUGIN_NAMES.GAIUS_CUSTOM_ERROR_PAGE]: {
    name: PLUGIN_NAMES.GAIUS_CUSTOM_ERROR_PAGE,
    config: {
      error_401: null,
      error_404: null,
      error_425: null,
      error_451: null,
      error_502: null,
    }
  },
  [PLUGIN_NAMES.GAIUS_UPSTREAM_HOST]: {
    name: PLUGIN_NAMES.GAIUS_UPSTREAM_HOST,
    config: {
      hostname: ''
    },
    enabled: true,
  },
  [PLUGIN_NAMES.GAIUS_PROXY_CACHE]: {
    name: PLUGIN_NAMES.GAIUS_PROXY_CACHE,
    config: {
      request_method: ['GET', 'HEAD', 'OPTIONS'],
      response_code: [
        200,
        301,
        302
      ],
      cache_postfixes: ['m3u8', 'ts', 'mp3', 'mp4', 'bmp', 'css', 'cur', 'js', 'jpg', 'jpeg', 'gif', 'htc', 'ico', 'png', 'swf', 'otf', 'ttf', 'eot', 'webp', 'woff', 'woff2', 'svg', 'jar', 'apk', 'ipa', 'zip', 'rar', 'tif', 'flv', 'mov', 'wmv', 'wav', 'cff', 'afm', 'lwfn', 'ffil', 'fon', 'pfm', 'pfb', 'std', 'pro', 'xsf', 'pdf', 'doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'epub', 'odt', 'odp', 'ods', 'txt', 'rtf'],
      bypass_rules: [],
      enable_cache_control: false,
      enable_cache_fullsize: false,
      cache_paths: ["/static", "/uploads"]
    },
    enabled: true
  },
  [PLUGIN_NAMES.GAIUS_ADVANCE_PROXY_CACHE]: {
    name: PLUGIN_NAMES.GAIUS_ADVANCE_PROXY_CACHE,
    config: {
      enable_cache_control: false,
      global_default_caching: true,
      global_disable_cache_completely: false,
      global_custom_caching: false,
      cache_ttl: 7200,
      request_method: ['GET', 'HEAD', 'OPTIONS'],
      response_code: [
        200,
        301,
        302
      ],
      content_type: ['css', 'image', 'javascript', 'audio'],
      bypass_rules: [],
    },
    enabled: true
  },
  [PLUGIN_NAMES.GAIUS_CROWDSEC_CAPTCHA]: {
    name: PLUGIN_NAMES.GAIUS_CROWDSEC_CAPTCHA,
    config: {
      findtime: 240,
      maxretry: 310,
      bantime: '3h',
    },
    enabled: true
  },
  [PLUGIN_NAMES.GAIUS_BLACKRULES]: {
    name: PLUGIN_NAMES.GAIUS_BLACKRULES, 
    config: {
      black_rules: []
    },
    enabled: true
  },
  [PLUGIN_NAMES.GAIUS_SECLINK]: {
    name: PLUGIN_NAMES.GAIUS_SECLINK,
    config: {
      api_secret: '',
      sleep_seconds: 5,
      expire_seconds: 300
    },
    enabled: true,
  },
  [PLUGIN_NAMES.GAIUS_BAN]: {
    name: PLUGIN_NAMES.GAIUS_BAN,
    config: {
      default_timeout: 3600
    },
    enabled: true,
  },
  [PLUGIN_NAMES.GAIUS_CUSTOM_HEADER]: {
    name: PLUGIN_NAMES.GAIUS_CUSTOM_HEADER,
    config: {
      add: {
        headers: []
      }
    },
    enabled: true,
  },
  [PLUGIN_NAMES.GAIUS_CONFIG]: {
    name: PLUGIN_NAMES.GAIUS_CONFIG,
    config: {
      is_bypass_acme: false,
    },
  },
};

export const TAG_NAMES = {
  'NO_JS_CHALLENGE': 'no_js_challenge="1"',
  // we only use this for UI to detect if a domain selection has mixed values for no_js_challenge=1. 
  // meaning 1 domain has no_js_challenge=1 and another domain does not have a no_js_challenge=1
  'NO_JS_CHALLENGE_MIXED': 'no_js_challenge="mixed"',
  'HAS_AUTO_GENERATE_CERTIFICATE': 'has_auto_generate_cert="1"'
}

export const EVENT_BUS_EVENTS = {
  'TASK_SCHEDULED': 'task_scheduled',
};

export const TASK_NAMES = {
  'CLEAR_CLEAR': 'clear_cache',
  'FORCE_HTTPS': 'force_https',
  'ENABLE_HTTPS': 'enable_https',
}

export const DEFAULT_CACHE_POST_FIXES = [
  'm3u8', 'ts', 'mp3', 'mp4', 'bmp', 'css', 'cur', 'js', 'jpg', 'jpeg',
  'gif', 'htc', 'ico', 'png', 'swf', 'otf', 'ttf', 'eot', 'webp',
  'woff', 'woff2', 'svg', 'jar', 'apk', 'ipa', 'zip', 'rar', 'tif',
  'flv', 'mov', 'wmv', 'wav', 'cff', 'afm', 'lwfn', 'ffil', 'fon',
  'pfm', 'pfb', 'std', 'pro', 'xsf', 'pdf', 'doc', 'docx', 'ppt',
  'pptx', 'xls', 'xlsx', 'epub', 'odt', 'odp', 'ods', 'txt', 'rtf'
]

export const UNCOVERED_NETWORK_TRAFFIC = 10; // increase 10% traffic for uncovered traffic on logs https://help.aliyun.com/document_detail/40164.html

export const OPT_OUT_NEW_ROOT_DOMAIN_STORAGE_KEY = 'OPT_OUT_NEW_ROOT_DOMAIN';
